import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Row, Visible} from 'react-grid-system';
import {NavLink, useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {
    Alert,
    Button,
    ButtonGroup,
    CheckBox,
    Icon,
    Input,
    Password,
    RadioBox,
    Spacer,
    SubTitle,
    Text,
    Theme, ThirdTitle,
    Title,
    Dropdown
} from '@dspworkplace/ui';
import {Card, Container, LightBlueSection, Loading, Skeleton} from "../../Components/UI";
import {Image, Menu, Tabs} from "../../Components/Menus/Main";
import {logo} from '../../assets';
import {onlyFourCharacter, patternDomain, patternEmail, patternPassword, patternPhone} from "../../patterns";
import api from "../../api";
import {Help} from "../Pricing";

const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);

const Nav = styled.nav`
    height: 95px;
    padding-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const CartItem = styled(Card)`
    display: flex;
    transition: all .2s;
    cursor: pointer;

    &:hover {
        box-shadow: 0 1px 6px ${Theme.colors.neutrals.black}33;
    }

    .price {
        font-size: 32px;
        align-self: center;
        margin-left: auto;
        text-align: right;
    }

    &:not(.checked) .price {
        color: ${Theme.colors.neutrals.silver};
    }
`;

const CartTotal = styled(LightBlueSection).attrs({
    right: 15
})`
    position: sticky;
    bottom: 0;
    background: ${Theme.colors.info.bg};
    margin: 0 -15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1024px) {
        position: fixed;
        left: 15px;
        right: 15px;
        z-index: 5;
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

// status map
const INITIAL = 0;
const LOADING = 1;
const ERROR = 2;
const REGISTERING = 3;

const Register = props => {
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const defaultValues = {
        users: 10,
        method: 'cc'
    };
    const {register, handleSubmit, errors, getValues, watch, reset} = useForm({
        mode: 'onTouched',
        defaultValues
    });
    const values = getValues();
    const [status, setStatus] = useState(INITIAL);
    const [error, setError] = useState();

    const API = api();

    const users = watch('users') > 10 ? watch('users') : 10;
    const [packages, setPackages] = useState({});
    const [addons, setAddons] = useState([]);
    const [pricing, setPricing] = useState('yearly');
    const [tier, setTier] = useState(null);
    const [station, setStation] = useState({});

    const {basic, partner} = packages;
    if (basic && partner)
        [basic, partner].forEach(p => {
            p.price = p.processorData.prices
                .find(price => price.lookup_key?.match(pricing));
            p.unitPrice = parseFloat((p.price.unit_amount_decimal / 100 * (pricing === 'yearly' ? 1 : 30))).toFixed(2)
        });

    const fetchPackages = () => {
        setStatus(LOADING);

        return Promise.all([
            API.fetchPackages().then(packages => setPackages(packages)),
            API.fetchAddons().then(addons => setAddons(addons))
        ]);
    }

    const onSubmit = async data => {
        if (!tier?.id) {
            setError('Please select a package.');
            return setStatus(ERROR);
        }

        setStatus(LOADING);

        let error, token;

        try {
            let result;
            if (data.method === 'cc') {
                const number = elements.getElement(CardNumberElement);
                result = await stripe.createToken(number);
            } else {
                result = await stripe.createToken('bank_account', {
                    country: 'US',
                    currency: 'usd',
                    ...data.ach
                })
            }

            error = result.error;
            token = result.token ? result.token.id : false;
        } catch (error) {
            setError(error.message);
            return setStatus(ERROR);
        }

        if (error) {
            setError(error.message);
            return setStatus(ERROR);
        }

        const payload = {
            ...data,
            firstName: data.name,
            token: token,
            company: {
                name: data.company,
                apiKey: data.email,
                numberOfUser: data.users,
                amazonAbbreviation: data.abbr,
                domain: data.domain,
                stationName:data.stationName,
                stationCode:data.stationCode
            },
            station:station,
            products: {
                package: {
                    [tier.id]: tier.price.id
                },
                addon: data.addons.reduce((all, id) => {
                    all[id] = addons.find(a => a.id == id).processorData.prices[0].id;
                    return all;
                }, {}),
            },
            pricing,
        }

        fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/api/user/registration`,
            {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            }
        ).then(result => {
            if (result.status !== 200)
                return result.json().then(response => {
                    setError(response.message);
                    setStatus(ERROR);
                });
            history.push('/preparing', {
                email: data.email,
                password: data.password
            });
        }).catch(reason => {
            setError(reason.message);
            setStatus(ERROR);
        });
    };

    const getFirstCharge = () => {
        let date = new Date();
        date.setTime(date.getTime() + (1000 * 60 * 60 * 24 * 30));
        return `
        ${['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]}
        ${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
           'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()]}.
        ${date.getDate()},
        ${date.getFullYear()}
        `.trim();
    };

    const getTotal = () => {
        const addonTotal = (watch('addons') || [])
            .reduce((all, next) => {
                const addon = addons.find(a => a.id === parseInt(next));

                if (!addon || addon.chargeAfterEvent)
                    return all;

                const qty = watch(next)?.qty
                    ? watch(next).qty
                    : addon.processorData.prices[0].recurring.usage_type === 'licensed'
                        ? 1
                        : users;

                return all + addon.processorData.prices[0].unit_amount_decimal / 100 * qty;
            }, 0);

        return (tier.unitPrice * users + addonTotal);
    };

    useEffect(() => {
        if (props.location.state) {
            const {packages, addons, pricing, tier, users} = props.location.state;

            if (packages && pricing) {
                setPackages(packages);
                setAddons(addons);
                setPricing(pricing);
                setTier(packages[tier]);
                reset({
                    ...defaultValues,
                    users: users
                });
            }
        } else
            fetchPackages().then(()=> setStatus(INITIAL));

        return () => API.abort();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
                <Nav>
                    <NavLink to={'/'} style={{maxWidth: 180}}>
                        <Image src={logo} />
                    </NavLink>

                    <Visible lg xl xxl>
                        <Menu>
                            <Tabs>
                                <li>
                                    <a href='https://dspworkplace.com'>
                                        <Icon.ArrowLeft
                                            size='16px'
                                            color={Theme.colors.neutrals.medium}
                                            style={{
                                                verticalAlign: 'middle',
                                                position: 'relative',
                                                top: -1
                                            }}
                                        />
                                        <Spacer right={2} inline/>
                                        Back to the site
                                    </a>
                                </li>
                            </Tabs>
                        </Menu>

                        <Spacer left={8} className='stack'>
                            <Text>Already has an account? <a href='https://app.dspworkplace.com/'>Login</a></Text>
                        </Spacer>
                    </Visible>

                    <Visible xs sm md>
                        <div style={{textAlign: 'right'}}>
                            <Text><a href='https://app.dspworkplace.com/'>Login</a></Text>
                            <Spacer top={3}/>
                            <a href='https://dspworkplace.com'>
                                <Text>
                                    <Icon.ArrowLeft
                                        size='16px'
                                        color={Theme.colors.neutrals.medium}
                                        style={{
                                            verticalAlign: 'middle',
                                            position: 'relative',
                                            top: -1
                                        }}
                                    />
                                    <Spacer right={2} inline/>
                                    Back to the site
                                </Text>
                            </a>
                        </div>
                    </Visible>
                </Nav>
            </Container>

            <Container fluid>
                <Row>
                    <Col lg={7} push={{lg: 5}} style={{
                        backgroundColor: Theme.colors.extra.whiteSmoke,
                    }}>
                        <Spacer style={{
                            position: 'sticky',
                            top: 0,
                        }}>
                            <Spacer top={10} bottom={10} left={6} right={10} style={{}}>
                                <SubTitle style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                    <div>
                                        Selected Package
                                        {tier !== null && <Spacer inline left={3}>
                                            <a
                                                href="#"
                                                onClick={()=>setTier(null)}
                                                style={{
                                                    color: Theme.colors.secondary,
                                                    fontSize: 'small'
                                                }}
                                            >change</a>
                                        </Spacer>}
                                    </div>

                                    <div>
                                        {pricing !== 'monthly' ? null : (
                                            <Spacer inline right={3}>
                                                <a
                                                    href="javascript:void(0);"
                                                    style={{
                                                        color: Theme.colors.info.text,
                                                        fontSize: 'small',
                                                    }}
                                                >
                                                    approximated price
                                                    <Spacer right={1} inline/>
                                                    <Help text={'We charge you only for active employees, pro-rated for the days they were active.'}>?</Help>
                                                </a>
                                            </Spacer>
                                        )}
                                        <ButtonGroup
                                            active={pricing}
                                            size='small'
                                            Type='button'
                                        >
                                            <Button track='monthly' onClick={()=>setPricing('monthly')}>Monthly</Button>
                                            <Button track='yearly' onClick={()=>setPricing('yearly')}>Annually</Button>
                                        </ButtonGroup>
                                    </div>
                                </SubTitle>
                                <Spacer top={3}/>

                                {tier === null && status === LOADING && <>
                                    <CartItem>
                                        <div>
                                            <Spacer top={1}/>
                                            <RadioBox
                                                name='pricing'
                                                options={[{value: 0}]}
                                            />
                                        </div>
                                        <Spacer right={5}>
                                            <Skeleton height={28} width={300}/>
                                            <Spacer top={1}/>

                                            <Skeleton height={28} width={140}/>
                                            <Spacer top={2}/>

                                            <Text className='desc'>
                                                <Skeleton height={36} width={500}/>
                                            </Text>
                                        </Spacer>
                                        <SubTitle className='price'>
                                            $<Skeleton height={28} width={40}/>
                                            <Spacer top={2}/>
                                            <Text>$<Skeleton height={20} width={32}/>/user/{pricing === 'monthly' ? 'month' : 'year'}</Text>
                                        </SubTitle>
                                    </CartItem>
                                    <Spacer bottom={3}/>
                                    <CartItem>
                                        <div>
                                            <Spacer top={1}/>
                                            <RadioBox
                                                name='pricing'
                                                options={[{value: 0}]}
                                            />
                                        </div>
                                        <Spacer right={5}>
                                            <Skeleton height={28} width={300}/>
                                            <Spacer top={1}/>

                                            <Skeleton height={28} width={140}/>
                                            <Spacer top={2}/>

                                            <Text className='desc'>
                                                <Skeleton height={36} width={500}/>
                                            </Text>
                                        </Spacer>
                                        <SubTitle className='price'>
                                            $<Skeleton height={28} width={40}/>
                                            <Spacer top={2}/>
                                            <Text>$<Skeleton height={20} width={32}/>/user/{pricing === 'monthly' ? 'month' : 'year'}</Text>
                                        </SubTitle>
                                    </CartItem>
                                </>}

                                {tier === null && status !== LOADING && [basic, partner].map(item =>
                                    !item ? null : <Spacer bottom={3} key={item.id}>
                                        <CartItem as={'label'} className='checked'>
                                            <div>
                                                <Spacer top={1}/>
                                                <RadioBox
                                                    name='pricing'
                                                    options={[{value: item.price.id}]}
                                                    onChange={() => setTier(item)}
                                                />
                                            </div>
                                            <Spacer right={5}>
                                                <SubTitle>{item.name}</SubTitle>
                                                <Spacer top={1}/>

                                                <ThirdTitle>Billed {pricing}</ThirdTitle>
                                                <Spacer top={1}/>

                                                {item.name.toLowerCase().match('early') && <Spacer bottom={2}>
                                                    <ThirdTitle>Early adopter pricing until 12/31/2020</ThirdTitle>
                                                </Spacer>}

                                                <Text className='desc'>{item.description}</Text>
                                            </Spacer>
                                            <SubTitle className='price'>
                                                ${item.unitPrice * users}
                                                <Spacer top={2}/>
                                                <Text>${item.unitPrice}/user/{pricing === 'monthly' ? 'month' : 'year'}</Text>
                                            </SubTitle>
                                        </CartItem>
                                    </Spacer>
                                )}

                                {tier && <CartItem className='checked'>
                                    <Spacer right={5}>
                                        <SubTitle>{tier.name || <Skeleton as={'span'} height={28} width={200}/>}</SubTitle>
                                        <Spacer top={1}/>

                                        <ThirdTitle>Billed {pricing}</ThirdTitle>
                                        <Spacer top={1}/>

                                        {tier.name.match('early') && <Spacer bottom={2}>
                                            <ThirdTitle>Early adopter pricing until 12/31/2020</ThirdTitle>
                                        </Spacer>}

                                        <Text className='desc'>{tier.description || <Skeleton as={'span'} height={32} width={300}/>}</Text>
                                    </Spacer>
                                    <SubTitle className='price'>
                                        ${tier.unitPrice * users || <Skeleton as={'span'} height={28} width={30} style={{display: 'inline-block'}}/>}
                                        <Spacer top={2}/>
                                        <Text>${tier.unitPrice || <Skeleton as={'span'} height={16} width={30}/>}/user/{pricing === 'monthly' ? 'month' : 'year'}</Text>
                                    </SubTitle>
                                </CartItem>}
                                <Spacer top={10} />

                                {tier && addons.length > 0 && (
                                    <Spacer bottom={3}>
                                        <SubTitle>Available Add-ons <span style={{color: Theme.colors.neutrals.medium, fontSize: 'small'}}>invoiced separately</span></SubTitle>
                                    </Spacer>
                                )}

                                {!tier ? null : addons.filter(addon =>
                                    (!addon.tier || (addon.tier && addon.tier === tier.tier))
                                    &&
                                    (!addon.interval || (addon.interval && addon.interval === pricing))
                                ).map(item =>
                                    <Spacer bottom={5} key={item.id}>
                                        <CartItem as={'label'} className={(watch('addons') || []).includes(item.id.toString()) ? 'checked' : ''}>
                                            <div>
                                                <Spacer top={1}/>
                                                <CheckBox
                                                    name='addons'
                                                    ref={register}
                                                    options={[{value: item.id}]}
                                                />
                                            </div>
                                            <Spacer right={5}>
                                                <SubTitle>{item.name}</SubTitle>
                                                <Spacer top={1}/>

                                                <Text className='desc'>{item.description}</Text>

                                                {item.processorData.prices[0].metadata.category === 'hosting' &&
                                                    (watch('addons') || []).includes(item.id) && (
                                                    <Spacer top={4}>
                                                        <Input
                                                            name='domain'
                                                            ref={register({
                                                                required: 'Required field',
                                                                pattern: {
                                                                    value: patternDomain,
                                                                    message: 'Invalid format'
                                                                },
                                                                validate: async value => (
                                                                    await fetch(
                                                                        `${process.env.REACT_APP_API_ENDPOINT}/api/company/check/domain/exist`,
                                                                        {
                                                                            method: 'post',
                                                                            headers: {'Content-Type': 'application/json'},
                                                                            body: JSON.stringify({"domain": value})
                                                                        }
                                                                    )
                                                                    .then(res => res.json())
                                                                    .then(res => res.status !== true) || "This domain is already in use"
                                                                )
                                                            })}
                                                            label='Website Domain'
                                                            Required={true}
                                                            error={errors.domain && errors.domain.message}
                                                            valid={!errors.domain && values.domain}
                                                        />
                                                    </Spacer>
                                                )}

                                                {item.processorData.prices[0].metadata.have_qty &&
                                                    (watch('addons') || []).includes(item.id) && (
                                                    <Spacer top={4}>
                                                        <Input
                                                            name={`${item.id}.qty`}
                                                            ref={register({
                                                                required: 'Required field',
                                                                pattern: {
                                                                    value: /^\d+$/,
                                                                    message: 'Only numbers'
                                                                },
                                                            })}
                                                            label='Quantity'
                                                            size='small'
                                                            type='number'
                                                            defaultValue={users}
                                                            Required={true}
                                                            error={errors[`${item.id}.qty`] && errors[`${item.id}.qty`].message}
                                                            valid={!errors[`${item.id}.qty`] && values[`${item.id}.qty`]}
                                                        />
                                                    </Spacer>
                                                )}
                                            </Spacer>
                                            <SubTitle className='price'>
                                                {(() => {
                                                    const {
                                                        unit_amount_decimal: amount,
                                                        recurring
                                                    } = item.processorData.prices[0];

                                                    if (amount <= 0)
                                                        return 'Free';

                                                    const chargeAfterUsage = item.chargeAfterEvent;
                                                    const qty = watch(item.id)?.qty ? watch(item.id).qty : users;

                                                    const total = amount / 100 * (chargeAfterUsage ? 1 : recurring.usage_type === 'licensed' ? 1 : qty)

                                                    return `$${total % 1 === 0 ? total : total.toFixed(2)}`;
                                                })()}
                                                {item.processorData.prices[0].unit_amount_decimal > 0 ? (
                                                    <Spacer top={2}>
                                                        <Text>${item.processorData.prices[0].unit_amount_decimal/100}/{item.processorData.prices[0].recurring.usage_type === 'metered' ? (item.processorData.unit_label ?? 'unit') : item.processorData.prices[0].recurring.interval}</Text>
                                                    </Spacer>
                                                ) : false}
                                            </SubTitle>
                                        </CartItem>
                                    </Spacer>
                                )}
                            </Spacer>

                            {tier?.id && <CartTotal>
                                <Title>
                                    {pricing === 'monthly' ? 'Monthly' : 'Annual'} Total
                                    <Text style={{fontWeight: 'normal'}}>
                                        Your first charge will be {getFirstCharge()}
                                    </Text>
                                </Title>
                                <Title>
                                    ${getTotal() || <Skeleton height={40} width={50}/>}
                                </Title>
                            </CartTotal>}
                        </Spacer>
                    </Col>
                    <Col lg={5} pull={{lg: 7}}>
                        <Spacer top={5} style={{
                            maxWidth: 300,
                            margin: 'auto'
                        }}>
                            <Visible xs sm md>
                                <Spacer top={5}/>
                            </Visible>
                            <Title>Sign Up</Title>

                            <Spacer top={3}/>
                            <SubTitle>Account Details</SubTitle>
                            <Spacer top={3}/>

                            <Input
                                name='company'
                                label='Company Name'
                                ref={register({
                                    required:true
                                })}
                                Required={true}
                                error={errors.company && 'Required field'}
                                valid={!errors.company && values.company}
                            />
                            <Spacer top={4} />

                            <Input
                                name='name'
                                label='Your Name'
                                ref={register({
                                    required:true
                                })}
                                Required={true}
                                error={errors.name && 'Required field'}
                                valid={!errors.name && values.name}
                            />
                            <Spacer top={4} />

                            <Input
                                name='phoneNumber'
                                label='Your Phone Number'
                                ref={register({
                                    required: 'Required field',
                                    pattern: {
                                        value: patternPhone,
                                        message: 'Make sure you have a valid phone number'
                                    }
                                })}
                                Required={true}
                                help='(000) 000-0000'
                                error={errors.phoneNumber && errors.phoneNumber.message}
                                valid={!errors.phoneNumber && values.phoneNumber}
                            />
                            <Spacer top={4} />

                            <Input
                                name='email'
                                label='Your Email'
                                ref={register({
                                    required: 'Required field',
                                    pattern: {
                                        value: patternEmail,
                                        message: 'Make sure your email is valid'
                                    },
                                    validate: async value => (
                                        await fetch(
                                            `${process.env.REACT_APP_API_ENDPOINT}/api/user/check/email/exist`,
                                            {
                                                method: 'post',
                                                headers: {'Content-Type': 'application/json'},
                                                body: JSON.stringify({"email": value})
                                            })
                                            .then(res => res.json())
                                            .then(res => res.status === false) || 'This email is already in use'
                                    )
                                })}
                                type='email'
                                Required={true}
                                error={errors.email && errors.email.message}
                                valid={!errors.email && values.email}
                                autoComplete='username'
                            />
                            <Spacer top={4} />

                            <Password
                                name='password'
                                label='Password'
                                ref={register({
                                    required: 'Required field',
                                    pattern: {
                                        value: patternPassword.upper_lower_number,
                                        message: 'Review your password, it does not pass all rules'
                                    }
                                })}
                                Required={true}
                                error={errors.password && errors.password.message}
                                valid={!errors.password && values.password}
                                help='Must have lowercase, UPPERCASE, numbers, symbols and min 8 characters'
                                autoComplete='current-password'
                            />
                            <Spacer top={4}/>

                            <Input
                                name='abbr'
                                label='Amazon Abbreviation'
                                ref={register({
                                    required: 'Required field',
                                    pattern: {
                                        value: onlyFourCharacter,
                                        message: 'Invalid code'
                                    }
                                })}
                                Required={true}
                                error={errors.abbr && errors.abbr.message}
                                valid={!errors.abbr && values.abbr}
                                size='small'
                            />
                            <Spacer inline left={5}/>
                            <Input
                                name='users'
                                label='Number of Employees'
                                ref={register({
                                    required: 'Required field',
                                    pattern: {
                                        value: /^\d+$/,
                                        message: 'Only numbers'
                                    },
                                    min: {
                                        value: 10,
                                        message: 'Minimum of 10 users'
                                    }
                                })}
                                type='tel'
                                Required={true}
                                error={errors.users && errors.users.message}
                                valid={!errors.users && values.users}
                                size='small'
                                help='Employees can be drivers, dispatchers, HR, etc'
                            />

                            <Spacer top={4} />
                            <Input
                                name='stationCode'
                                label='Station Code (ex. DSD1)'
                                ref={register({
                                    required: 'Required field',
                                    validate: async value => (
                                        await fetch(
                                            `${process.env.REACT_APP_API_ENDPOINT}/api/user/check/code/exist`,
                                            {
                                                method: 'post',
                                                headers: {'Content-Type': 'application/json'},
                                                body: JSON.stringify({"code": value})
                                            })
                                            .then(res =>
                                                res.json()
                                            )
                                            .then(res => { setStation(res.data); return res.status}) || 'This code does not exist, Please enter valid code'
                                    )
                                })}
                                Required={true}
                                error={errors.stationCode && errors.stationCode.message}
                                valid={!errors.stationCode && values.stationCode}
                            />

                            <Spacer top={4} />
                            <Input
                                name='stationName'
                                label='Station Name'
                                ref={register({
                                    required: 'Required field',
                                })}
                                Required={true}
                                error={errors.stationName && errors.stationName.message}
                                valid={!errors.stationName && values.stationName}
                            />
                            <Spacer top={4} />
                            <Dropdown
                                size="medium"
                                name="timezone"
                                label="Timezone"
                                ref={register}
                                options={[
                                    { name: "Eastern Time", value: "ET" },
                                    { name: "Central Time", value: "CT" },
                                    { name: "Mountain Time", value: "MT" },
                                    { name: "Pacific Time", value: "PT" },
                                    { name: "Alaska Time", value: "AKT" },
                                    { name: "Hawaii Time", value: "HT" },
                                    { name: "Arizona Time", value: "AZ" },
                                    { name: "Central European Time", value: "CET" }
                                ]}
                            />
                            <Spacer top={5} />

                            <SubTitle>Payment Details</SubTitle>
                            <Spacer top={3}/>

                            <RadioBox
                                name='method'
                                ref={register}
                                options={[
                                    {value: 'cc', label: 'Credit Card'},
                                    {value: 'ach', label: 'ACH'},
                                ]}
                            />
                            <Spacer top={4}/>

                            {watch('method') === 'cc' && (
                                <>
                                    <Input
                                        as={CardNumberElement}
                                        name='cc.number'
                                        label={'Card Number'}
                                    >
                                        <Icon.CreditCard left={1}/>
                                    </Input>
                                    <Spacer top={4}/>

                                    <Input
                                        as={CardExpiryElement}
                                        name='cc.exp'
                                        label='Exp. Date'
                                        size='small'
                                    />
                                    <Spacer inline right={5}/>
                                    <Input
                                        as={CardCvcElement}
                                        name='cc.cvc'
                                        label='CVC'
                                        size='small'
                                    />
                                </>
                            )}

                            {watch('method') === 'ach' && (
                                <>
                                    <Input
                                        name='ach.routing_number'
                                        label='Routing Number'
                                        ref={register({
                                            required: true
                                        })}
                                        Required={true}
                                    />
                                    <Spacer bottom={4}/>
                                    <Input
                                        name='ach.account_number'
                                        label='Account Number'
                                        ref={register({
                                            required: true
                                        })}
                                        Required={true}
                                    />
                                    <Spacer bottom={4}/>
                                    <RadioBox
                                        name='ach.account_holder_type'
                                        title='Account Type'
                                        ref={register({
                                            required: true
                                        })}
                                        Required={true}
                                        options={[
                                            {value: 'individual', label: 'Individual'},
                                            {value: 'company', label: 'Company'},
                                        ]}
                                    />
                                    <Spacer bottom={4}/>
                                    <Input
                                        name='ach.account_holder_name'
                                        label='Holder Name'
                                        ref={register({
                                            required: true
                                        })}
                                        Required={true}
                                    />
                                </>
                            )}

                            {status === ERROR && (
                                <Spacer top={5}>
                                    <Alert.Error size='small' useIcon={true} title={error}/>
                                </Spacer>
                            )}

                            <Spacer top={5}/>
                            <Button
                                type={status === LOADING || status === REGISTERING ? 'cancel' : 'primary'}
                                Type='submit'
                                style={{width: 300}}
                                disabled={status === LOADING || status === REGISTERING}
                            >
                                {status === LOADING || status === REGISTERING ? <Loading/> : 'Start my free 30-day trial'}
                            </Button>

                            <Spacer top={5} bottom={20} style={{display: 'flex'}}>
                                <Icon.Locker size='36px'/>
                                <Spacer right={1} inline/>
                                <Title style={{fontSize: '24px', lineHeight: .95}}>
                                    100% Secure Transaction
                                    <Text style={{fontWeight: 'normal'}}><small>Your payment will be safely processed by Stripe</small></Text>
                                </Title>
                            </Spacer>

                            <Visible xs sm md>
                                <Spacer top={20}/>
                            </Visible>
                        </Spacer>
                    </Col>
                </Row>
            </Container>
        </form>
    );
}

const App = props => {
    return (
        <Elements stripe={stripePromise}>
            <Register {...props}/>
        </Elements>
    );
}

export default App;
